<template>
  <div class="index">
    <!-- 轮播图 -->
    <div class="content_header">
      <div class="banner-content" v-if="bannerList.length > 0">
        <div class="swiper_banner">
          <swiper class="banner" ref="mySwiper" :options="swiperOptionsBanner">
            <swiper-slide v-for="(item, index) in bannerList" :key="item.image + '_' + index">
              <a :href="item.link" target="_blank">
                <img class="banner-img" :src="item.image">
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="banner_box_bgm" v-else></div>
      <!--  -->
      <div class="left_menu" v-if="is_show_sidebar == 1">
        <ul>
          <li class="menus" v-for="item in menuAllList" :key="item.id">
            <div class="show_menu flex">
              <p class="title">
                <span>
                  <span style="margin-left: 5px" @click="menuClickFirst(item)">{{ item.name }}</span>
                </span>
              </p>
              <p class="list_title" v-if="item.son && item.son.length > 0">
                <template v-if="item.son && item.son.length > 2">
                  <span v-for="(sonText, index) in item.son.slice(0, 2)" :key="sonText.id" @click="menuClick(item.id, sonText)">
                    <span v-if="index > 0"> /</span> {{ sonText.name }}
                  </span>
                </template>
                <template v-else>
                  <span v-for="(sonText, index) in item.son" :key="sonText.id" @click="menuClick(item.id, sonText)">
                    <span v-if="index > 0"> /</span> {{ sonText.name }}
                  </span>
                </template>
              </p>
            </div>
            <div class="hide_menu">
              <div class="menu_cont">
                <div class="top_title">分类方向</div>
                <ul class="menuList flex">
                  <li v-for="sonText in item.son" :key="sonText.id" @click="menuClick(item.id, sonText)">
                    {{ sonText.name }}
                  </li>
                </ul>
                <div class="top_title" v-if="item.hotCourse && item.hotCourse.length > 0">
                  热门课程
                </div>
                <ul class="img_tex flex">
                  <template v-if="item.hotCourse && item.hotCourse.length >= 4">
                    <li v-for="hotCourse in item.hotCourse.slice(0, 4)" :key="hotCourse.id"
                      @click="$router.push('/recordedInfo/' + hotCourse.id)">
                      <p class="img">
                        <img :src="hotCourse.image" alt="" />
                      </p>
                      <p class="text">{{ hotCourse.name }}</p>
                    </li>
                  </template>
                  <template v-else>
                    <li v-for="hotCourse in item.hotCourse" :key="hotCourse.id"
                      @click="$router.push('/recordedInfo/' + hotCourse.id)">
                      <p class="img">
                        <img :src="hotCourse.image" alt="" />
                      </p>
                      <p class="text">{{ hotCourse.name }}</p>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-box-list">
      <!-- 直播课堂 -->
      <div class="content-box live-content" v-if="liveModule.length > 0">
        <div class="content clearfix">
          <div class="title-content">
            <div class="title"><span class="title-text"><span class="text">工匠大讲堂</span></span></div>
            <div class="more" @click="$router.push('/liveList')">查看更多</div>
          </div>
          <div class="swiper-box live-swiper">
            <swiper class="swiper-org" :options="swiperLiveOption">
              <swiper-slide class="swiper-slide" v-for="item in liveModule" :key="item.id">
                <div class="live-info" @click="$router.push('/orgInfo?id='+item.id)">
                  <img class="live-img" :src="item.image">
                  <div class="live-conten">
                    <div class="live-name">
                      <span class="live-status" :class="item.status=='直播中' ? 'play' : 'noplay'">{{item.status}}</span>
                      <span class="live-title-text">{{ item.name }}</span>
                    </div>
                    <div class="live-date">{{ item.live_date }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev swiper-live-prev" slot="button-prev"></div>
            <div class="swiper-button-next swiper-live-next" slot="button-next"></div>
          </div>
        </div>
      </div>

      <div class="loadState" v-if="loadState">
        <ul>
          <li>
            <div class="title"></div>
            <div class="list">
              <p></p>
              <p></p>
              <p></p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 模块 -->
      <div v-for="(item, index) in modulelist" :key="item.id" class="content-box clearfix" :class="'style_' + item.style_id" v-if="item.list && item.list.length > 0">
        <div class="content" v-if="item.style_id == 1 || item.style_id == 2 || item.style_id == 3">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">{{ item.name }}</span></span>
            </div>
            <div class="more" @click="$router.push('/courseList?name=' + item.name + '&id=' + item.id)">查看更多</div>
          </div>
          <ul class="data-list">
            <li v-for="subItem in item.list" :key="subItem.id" class="data-info">
              <router-link :to="'/recordedInfo/' + subItem.data_id">
                <img v-lazy="subItem.image" alt="" class="data-img" :key="subItem.image" />
                <div class="data-box">
                  <div class="data-title">{{ subItem.name }}</div>
                  <div class="data-tip">{{ subItem.duration }}课时</div>
                  <div class="data-price" v-if="websiteData.is_course_pay == 1">
                    <span class="price"><span v-if="subItem.price != '0.00'">￥</span>{{ subItem.price == "0.00" ? "免费" : subItem.price }}</span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        
        <!-- 讲师 -->
        <div class="content lecturer-content" v-if="item.style_id == 4">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">{{ item.name }}</span></span>
            </div>
            <div class="more" @click="$router.push('/teacherList?id=' + item.id)">查看更多</div>
          </div>
          <div class="lecturer-box">
            <div class="lecturer-info">
              <img class="lecturer-img" :src="lecturerActive.avatar" />
              <div class="lecturer-info-content">
                <div class="lecturer-info-name">
                  <div class="lecturer-info-name-text">{{ lecturerActive.name }}</div>
                  <div class="lecturer-info-level">{{ lecturerActive.level }}</div>
                </div>
                <div class="lecturer-info-introduce">{{ lecturerActive.introduce }}</div>
              </div>
            </div>
            <div class="lecturer-list">
              <div class="lecturer-avartar-box" :class="{'active': subItem.id==lecturerActive.id}" v-for="(subItem, index) in item.list" :key="subItem.id" v-show="index<4" @click="lecturerActive=subItem">
                <img class="lecturer-avartar" :src="subItem.avatar" />
              </div>
            </div>
          </div>
        </div>

        <div class="content series-content series-content5" v-if="item.style_id == 5">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">{{ item.name }}</span></span>
            </div>
            <div class="more" @click="$router.push('/specialList?name=' + item.name + '&id=' + item.id)">查看更多</div>
          </div>
          <ul class="series-list">
            <li class="series-info" v-for="subItem in item.list.slice(0, 3)" :key="subItem.data_id" @click="$router.push('/seriesLessonsInfo/' + subItem.data_id)">
              <img :src="subItem.image" class="series-img" />
              <div class="series-text">
                <div class="series-title">{{ subItem.name }}</div>
                <div class="marks">
                  <div class="mark" v-for="mark in subItem.tags" :key="mark">{{ mark }}</div>
                </div>
                <div class="series-bottom">
                  <div class="series-bottom-top">
                    <p class="series-price" v-if="websiteData.is_course_pay == 1">￥{{ subItem.price }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="content series-content  series-content6" v-if="item.style_id == 6">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">{{ item.name }}</span></span>
            </div>
            <div class="more" @click="$router.push('/specialList?name=' + item.name + '&id=' + item.id)">查看更多</div>
          </div>
          <ul class="series-list">
            <li class="series-info" v-for="subItem in item.list.slice(0, 8)" :key="subItem.data_id" @click="$router.push('/seriesLessonsInfo/' + subItem.data_id)">
              <img :src="subItem.image" class="series-img" />
              <div class="series-text">
                <div class="series-title">{{ subItem.name }}</div>
                <div class="marks">
                  <div class="mark" v-for="mark in subItem.tags" :key="mark">{{ mark }}</div>
                </div>
                <div class="series-bottom">
                  <div class="series-bottom-top">
                    <p class="series-price" v-if="websiteData.is_course_pay == 1">￥{{ subItem.price }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="content active-content" v-if="item.style_id == 7 || item.style_id == 8">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">{{ item.name }}</span></span>
            </div>
            <div class="more" @click="$router.push('/activeList/' + item.id)">查看更多</div>
          </div>
          <div class="news-content" v-if="item.id==464">
            <div class="news-left" @click="toActiveInfo(newsActive)">
              <img class="news-img" :src="newsActive.image">
              <!-- <div class="news-left-content">
                <p class="news-title a-single-ellipsis">{{ newsActive.name }}</p>
                <p class="news-sub-title a-single-ellipsis">{{ newsActive.desc }}</p>
                <p class="news-date">{{ newsActive.created_at.split(' ')[0] }}</p>
              </div> -->
            </div>
            <div class="new-list">
              <div class="news-info" :class="{'active': newsActive.id==active.id}" v-for="active in item.list" :key="active.id" @click="newsActive=active" >
                <div class="news-info-title a-single-ellipsis">{{ active.name }}</div>
                <div class="news-info-date">{{ active.created_at.split(' ')[0] }}</div>
              </div>
            </div>
          </div>
          <ul class="active-list" :class="['style_' + item.style_id, item.id==465?'style_465':'']" v-else>
            <li class="active-info" v-for="active in item.list" :key="active.id" @click="toActiveInfo(active)">
              <img class="active-img" :src="active.image">
              <div class="active-cent">
                <p class="active-name vertical-ellipsis">{{ active.name }}</p>
                <p class="active-sub-title vertical-ellipsis">{{ active.desc }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="vert_box" v-if="advertList[index]">
          <div class="advert_image">
            <a :href="advertList[index].pc_link" target="_blank">
              <img :src="advertList[index].pc_image" alt="">
            </a>
          </div>
        </div>
      </div>

      <div class="content-box course-module">
        <div class="content">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">职工课堂</span></span>
            </div>
            <div class="more" @click="$router.push('/courseList?name=' + courseTypeName + '&id=' + courseTypeId)">查看更多</div>
          </div>
          <div class="course-types">
            <div class="course-type" :class="{'active': courseTypeId==item.id}" v-for="item in courseType" :key="item.id" @click="courseTypeClick(item)">{{ item.name }}</div>
          </div>
          <ul class="data-list">
            <li v-for="subItem in courseList" :key="subItem.id" class="data-info">
              <router-link :to="'/recordedInfo/' + subItem.data_id">
                <img v-lazy="subItem.image" alt="" class="data-img" :key="subItem.image" />
                <div class="data-box">
                  <div class="data-title">{{ subItem.name }}</div>
                  <div class="data-tip">{{ subItem.duration }}课时</div>
                  <div class="data-price" v-if="websiteData.is_course_pay == 1">
                    <span class="price"><span v-if="subItem.price != '0.00'">￥</span>{{ subItem.price == "0.00" ? "免费" : subItem.price }}</span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="loadState" v-if="loadState1">
        <ul>
          <li>
            <div class="title"></div>
            <div class="list">
              <p></p>
              <p></p>
              <p></p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 文本课程 -->
      <div class="content-box clearfix textCourse" v-if="textCourseList.length > 0">
        <div class="content lecturer-content">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">文本课程</span></span><span class="sub-title"></span>
            </div>
            <div class="more" @click="$router.push('/textCourse/list')">查看更多</div>
          </div>
          <ul class="lecturer-list" v-if="textCourseList.length > 0">
            <li class="lecturer-info" v-for="subItem in textCourseList" :key="subItem.id" @click="$router.push('/textCourse/detail?id=' + subItem.id)">
              <img :src="subItem.image" class="lecturer-img" />
              <div class="lecturer-name">{{ subItem.title }}</div>
              <div class="lecturer-course"><span>浏览量：</span>{{ subItem.view_number }}</div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 开放式班级 -->
      <div class="content-box clearfix textCourse" v-if="openClassList.length > 0">
        <div class="content lecturer-content">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">推荐班级</span></span><span class="sub-title"></span>
            </div>
            <div class="more" @click="$router.push('/openClass')">查看更多</div>
          </div>
          <ul class="lecturer-list" v-if="openClassList.length > 0">
            <li class="lecturer-info openBox" v-for="subItem in openClassList" :key="subItem.id">
              <!-- <img :src="subItem.image" class="lecturer-img" /> -->
              <div class="lecturer-name">{{ subItem.name }}</div>
              <div class="lecturer-course" v-if="subItem.join_limit_number > 0">
                <span>班级名额:</span>{{ subItem.join_limit_number }}
              </div>
              <div class="btn">
                <p @click="sightBtn(subItem.id)">报名</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 公开考试 -->
      <div class="content-box clearfix examCont" v-if="commonExamList.length > 0">
        <div class="content lecturer-content">
          <div class="title-content">
            <div class="title">
              <span class="title-text"><span class="text">公开考试</span></span><span class="sub-title"></span>
            </div>
            <div class="more" @click="$router.push('/commonExam/list')">查看更多</div>
          </div>
          <ul :class="'style_'+siteInfo.id">
            <li v-for="examItem in commonExamList" :key="examItem.id">
              <img class="exam-img" src="~@/assets/gx/exam-img.png" v-if="siteInfo.id==111">
              <p class="exam_name">{{ examItem.exam_name }}</p>
              <div class="time_">
                <p>开始时间：{{ examItem.start_date }}</p>
                <p>结束时间：{{ examItem.end_date }}</p>
              </div>
              <div class="down_num">
                限制考试次数：{{ examItem.limit_desc }}
              </div>
              <div class="btn" @click="$router.push('/commonExam/text/' + examItem.id)">去考试</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 全国工匠学院作业提示弹框 -->
    <div v-if="taskHintDialog" class="taskHint-dialog">
      <div class="taskHint-content">
        <i class="el-icon-close close" @click="taskHintDialog = false"></i>
        <div class="taskHint-img">
          <img src="@/assets/pc/lmxy_icon.png" alt="">
        </div>
        <div class="taskHint-text">
          <p class="title">作业提示</p>
          <p class="text">完成全部课程学习后，需要点击按钮“填写学习心得”进行填写或上传，截止日期为11月7日24时。</p>
          <a class="but" href="https://qggjxy.org.cn/userCenter" target="_blank">填写学习心得</a>
        </div>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div v-if="taskHintDialog" class="maskSight-box"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapMutations } from "vuex";
import https from 'https'

export default {
  name: "Index",
  data() {
    return {
      bannerList: [], //banner
      liveModule: [], //直播模块
      liveSelect: "", //直播模块选中
      modulelist: [], //模块所有数据
      blogroll: [], //友情链接
      menuAllList: [], //menu
      is_show_sidebar: "0",
      swiperOptionsBanner: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //是否可点击
        },
      },
      websiteData: "",
      textCourseList: [], //文本课程
      openClassList: "", //开放式班级
      loadState: true,
      loadState1: true,

      loading: '',
      advertList: [],//广告位
      commonExamList: [],//公开考试列表
      // 模块
      moduleListHead: [],
      isActive: 0,
      moduleItem: {},
      taskHintDialog: false, // 劳模学院提示
      siteInfo: {},
      lecturerActive: {},
      newsActive: {},
      swiperLiveOption: {
        speed: 500,
        initialSlide: 1,
        slidesPerView: 2,
        centeredSlides: true,
        watchSlidesProgress: true,
        grabCursor: true,
        effect: "creative",
        navigation: {
          nextEl: '.swiper-live-next',
          prevEl: '.swiper-live-prev',
        },
        on: {
          click: (v) => {
            this.$router.push('/studentDetail?id=' + v.target.dataset.id)
          },
          progress: function () {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              let modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              let translate = slideProgress * modify * 220 + "px";
              let scale = 1 - Math.abs(slideProgress) / 6;
              let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform(
                "translateX(" + translate + ") scale(" + scale + ")"
              );
              slide.css("zIndex", zIndex);
              slide.css("opacity", 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css("opacity", 0);
              }
            }
          },
          setTransition: function (transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
        },
      },
      courseType: [],
      courseTypeId: '',
      courseList: [],
      courseTypeName: ''
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  created() {
    this.getWebsiteInfo()
  },
  mounted() {
    this.getNeedLogin(); //判断是否需要登录
    this.getBenner(); //获取banner
    this.getLliveModule(); //获取直播课堂
    this.getModule(); //获取首页模块
    this.getTextCourse(); //获取文本课程
    this.getAdvert();//获取广告条
    this.getOpenExam();//获取公开考试
    // 劳模学院塞上工匠提示框显示隐藏
    // if (this.siteInfo.id == 108) {
    //   console.log(Number(this.$route.query.studyProgress));
    //   console.log(localStorage.getItem('taskHintDialog'));

    //   if (Number(this.$route.query.studyProgress) >= 100) {
    //     if (!localStorage.getItem('taskHintDialog')) {
    //       this.taskHintDialog = true
    //       localStorage.setItem("taskHintDialog", "true");
    //     }
    //   }
    // }
    // this.websiteData = JSON.parse(localStorage.getItem('websiteState'))
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "ShowTipModal", "CloseTipModal"]), //登录弹窗
    //选择直播
    selectLive(data) {
      this.liveSelect = data;
    },
    getChannelNo() {
      this.$router.push("/live/" + this.liveSelect.channel_no + '/' + this.liveSelect.id);
    },
    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        this.siteInfo = res.data.site_info;
        // 劳模学院塞上工匠提示框显示隐藏
        if (res.data.site_info.id == 108) {
          if (Number(this.$route.query.studyProgress) >= 100) {
            if (!localStorage.getItem('taskHintDialog')) {
              this.taskHintDialog = true
              localStorage.setItem("taskHintDialog", "true");
            }
          }
        }
      });
    },
    //判断是否需要登录
    getNeedLogin() {
      if (localStorage.getItem("needLogin")) {
        this.ShowLoginModal();
        localStorage.removeItem("needLogin");
      }
    },
    //获取banner
    getBenner() {
      this.$axios
        .post(`/v1/home/banner`, {
          type: "1",
        })
        .then((res) => {
          if (res.data.length == 1) {
            this.swiperOptionsBanner.loop = false;
          }
          this.bannerList = res.data;
        });
    },
    // 获取广告条
    getAdvert() {
      this.$axios
        .post(`/v1/home/advert`, {
          type: "1",
        })
        .then((res) => {
          if (res.code == 0) {
            this.advertList = res.data
          }
        });
    },
    // 菜单
    menuClickFirst(item) {
      this.$router.push({
        path: "/courseSelection",
        query: {
          pid: item.id,
        },
      });
    },
    menuClick(id, item) {
      this.$router.push({
        path: "/courseSelection",
        query: {
          pid: id,
          id: item.id,
        },
      });
    },

    //获取直播课堂
    getLliveModule() {
      this.$axios.post(`/v1/home/liveModule`).then((res) => {
        let nowDta = Date.now() / 1000;

        for (let i = 0; i < res.data.length; i++) {
          if (nowDta > res.data[i].live_time && nowDta < res.data[i].end_time) {
            res.data[i].liveNow = true;
          } else {
            res.data[i].liveNow = false;
          }
        }

        this.liveModule = res.data;
        this.liveSelect = res.data[0];
      });
    },
    //获取首页模块
    getModule() {
      // if(this.loadState){
      //  this.loading =  this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(255,255,255,.5)'
      //   });
      // }
      const agent = new https.Agent({
        rejectUnauthorized: false
      });
      this.$axios.post(`/v1/home/module`).then((res) => {
        this.getModuleData(res.data)
      });
      this.$axios.post(`/v1/home/category`).then((res) => {
        if (res.code == 0) {
          this.menuAllList = res.data;
        }
      });
      let data =
        window.location.host == "localhost:8081"
          ? "kskweb.kskstudy.com"
          : window.location.host;
      this.$axios.post(`/v1/home/getStatus`, { host: data }).then((res) => {
        if (res.code == 0) {
          this.is_show_sidebar = res.data.is_show_sidebar;
          this.websiteData = res.data;

          // this.menuAllList = res.data;
        }
      });
    },
    //获取各模块数据
    async getModuleData(moduleData) {
      this.modulelist = moduleData;

      if (this.modulelist <= 0) {
        this.loadState = false
        setTimeout(() => {
          this.loadState1 = false
        }, 1000)
      }
      for (let i = 0; i < moduleData.length; i++) {
        if(moduleData[i].type == 1){
          this.courseType.push(moduleData[i])
        }else{
          let res = await this.$axios.post(`/v1/home/moduleData`, {
            id: moduleData[i].id,
          });

          for (let i = 0; i < this.modulelist.length; i++) {
            if (res.data.total) {
              for (let o = 0; o < res.data.data.length; o++) {
                if (res.data.data[o].module_id == this.modulelist[i].id) {
                  if (this.modulelist[i].list) {
                    this.$set(this.modulelist[i].list, o, res.data.data[o]);
                  } else {
                    this.$set(this.modulelist[i], "list", []);
                    this.$set(this.modulelist[i].list, o, res.data.data[o]);
                  }
                }
              }
              if(res.data.data[0].module_id == 464){
                this.newsActive = res.data.data[0];
              }
            } else {
              for (let o = 0; o < res.data.length; o++) {
                if (res.data[o].module_id == this.modulelist[i].id) {
                  if (this.modulelist[i].list) {
                    this.$set(this.modulelist[i].list, o, res.data[o]);
                  } else {
                    this.$set(this.modulelist[i], "list", []);
                    this.$set(this.modulelist[i].list, o, res.data[o]);
                  }
                }
              }

              if(res.data[0].module_id == 463){
                this.lecturerActive = res.data[0];
              }
            }
          }
          this.loadState = false
          setTimeout(() => {
            this.loadState1 = false
          }, 1000)
        }
        // this.loading.close();
      }
      this.courseTypeClick(this.courseType[0])
    },
    //跳转活动文章详情
    toActiveInfo(data) {
      if (data.type === 0) {
        this.$router.push('/activeInfo/' + data.data_id);
      } else if (data.type === 1) {
        window.open(data.pc_link);
      }
    },
    // 文本课程
    getTextCourse() {
      // 文本课程
      this.$axios.post(`/v1/home/textCourse`).then((res) => {
        if (res.code == 0) {
          this.textCourseList = res.data;
        }
      });
      // 开放式班级
      this.$axios
        .post(`/v1/openClass/list`, { page: "1", per_page: "3" })
        .then((res) => {
          if (res.code == 0) {
            this.openClassList = res.data.data;
          }
        });
    },
    // 报名
    sightBtn(id) {
      let _this = this;
      _this.ShowTipModal({
        text: "确定报名该班级？", //提示弹窗内容
        showBtns: true,
        subBtnText: "报名",
        confirmFun: function () {
          _this.CloseTipModal();
          // _this.payNowBtn(_this.courseInfo.detail.id);
          this.$axios
            .post(
              `/v1/openClass/joinOpenClass`,
              { class_id: id },
              { useLog: true }
            )
            .then((res) => {
              _this.ShowTipModal({
                text: res.msg, //提示弹窗内容
              });
            });
        },
      });
    },
    // 获取公开考试
    getOpenExam() {
      this.$axios.post(`/v1/commonExam/list`, { page: 1, per_page: 4 }).then(res => {
        if (res.code == 0) {
          this.commonExamList = res.data.data
        }
      })
    },
    courseTypeClick(item){
      this.courseTypeId = item.id;
      this.courseTypeName = item.name;
      this.$axios.post(`/v1/home/moduleData`, { id: item.id }).then(res => {
        this.courseList = res.data.data
      })
    }
  },
};
</script>
<style scoped lang="scss">
.banner-content {
  width: 100%;
}

.banner_box_bgm {
  width: 100%;
  height: 400px;
  background: rgba(0, 0, 0, 0.06);
}

.banner {
  width: 100% !important;
  height: 100%;

  .banner-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content_header {
  position: relative;

  .swiper_banner {
    width: 100%;
    height: 640px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.left_menu::-webkit-scrollbar {
  display: none;
}

.left_menu {
  width: 310px;
  height: 400px;

  border-radius: 5px 0px 0px 5px;
  z-index: 9;
  opacity: 1;

  >ul {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    height: 400px;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);

    // overflow-y: auto;
    // overflow-x: hidden;
    .menus {
      flex: 1;
      min-height: 58px;
      color: #fff;
      display: flex;
      align-items: center;
    }

    >li.menus:hover {
      background: rgba(63, 63, 63, 0.5);

      .hide_menu {
        display: block;
      }
    }

    >li.menus {
      overflow: hidden;
      color: #333;
      cursor: pointer;
      width: 310px;

      div.show_menu {
        width: 310px;
        padding: 0 20px;
        display: flex;
        align-items: center;

        // position: relative;
        // z-index: 9;
        // width: 310px;
        // height: 50px;
        // background: ;
        p.title {
          justify-content: space-between;

          span {
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            display: inline-block;
            width: 110px;
          }

          i {
            font-size: 15px;
            color: #fff;
          }
        }

        p.list_title {
          font-size: 12px;
          color: #fff;
          margin-left: 16px;
        }
      }

      // 隐藏起来的菜单
      .hide_menu {
        display: none;
        position: absolute;
        left: 310px;
        top: 0;
        z-index: 9999;
        box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.9);
        width: 741px;
        height: 400px;
        z-index: 99;

        .menu_cont {
          padding: 24px 25px 0 22px;
          overflow-y: auto;

          div.top_title {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #000;
            margin-bottom: 14px;
          }

          .menuList {
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 30px;

            li {
              margin-right: 29px;
              height: 30px;
              font-size: 14px;
              color: #333333;
            }

            li:hover {
              color: #ff6711;
            }
          }

          .img_tex {
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 5px;
            align-items: flex-start;

            li {
              width: 155px;
              margin-right: 22px;
              margin-bottom: 15px;

              p.img {
                width: 155px;
                height: 84px;
                margin: 0 auto;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                }
              }

              p.text {
                font-size: 14px;
                color: #666666;
                margin-top: 10px;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space: normal;
                word-wrap: break-word;
              }
            }

            li:nth-child(4n) {
              margin-right: 0;
            }
          }

          .text_info {
            width: 100%;
            padding-bottom: 30px;

            li {
              flex-wrap: wrap;
              margin-right: 24px;
              width: 155px;
              height: 100px;
              position: relative;

              p.img {
                width: 155px;
                height: 100px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                }
              }

              p.text {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 155px;
                height: 23px;
                background: rgba(0, 0, 0, 0.5);
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                padding-left: 8px;
                color: #fff;
                line-height: 23px;
                z-index: 2;
              }

              span {
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 0 0 5px 5px;
              }

              span.box_shadow1 {
                width: 135px;
                height: 23px;
                background: rgba(0, 0, 0, 0.6);
                bottom: -6px;
                opacity: 0.6;
                // z-index: 0;
              }

              span.box_shadow2 {
                width: 125px;
                height: 23px;
                background: rgba(0, 0, 0, 0.4);
                opacity: 0.4;
                bottom: -12px;
                // z-index: 0;
              }
            }

            li:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.banner,
.content {
  width: 1200px;
  margin: 0 auto;

  .title-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 66px;
    position: relative;
    .title {
      font-size: 30px;
      color: #333;
      position: relative;

      .title-text {
        position: relative;

        .text {
          position: relative;
          z-index: 1;
        }

        &::after {
          content: "";
          display: block;
          width: 38px;
          height: 6px;
          background: #2066BB;
          border-radius: 4px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -20px;
          z-index: 0;
        }
      }
    }

    .sub-title {
      font-size: 16px;
      margin-left: 18px;
    }

    .more {
      height: 19.88px;
      padding-right: 22px;
      font-size: 14px;
      color: #999;
      background: url(~@/assets/arrows-right.png) no-repeat;
      background-size: 19.88px;
      background-position: right;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateX(-50%);
    }
  }
}

.banner-content ::v-deep .swiper-pagination-bullet {
  display: inline-block;
  width: 48px;
  height: 6px;
  background: #ccc;
  border-radius: 6px;
  margin: 0 10px;
}

.banner-content ::v-deep .swiper-pagination-bullet-active {
  background: #fff;
}

.content-box {
  width: 100%;
  padding: 100px 0;
  background: #F5F7FD;
  border-top: 10px solid #fff;

  .data-list {
    width: 1224px;
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
  }

  .data-info {
    width: 282px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    margin: 0 12px 24px 12px;
    transition: all 0.2s ease-out;

    &:hover {
      position: relative;
      transform: translateY(-6px);
    }
  }

  .data-img {
    width: 100%;
    height: 156px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 15px;
  }

  .data-box {
    padding: 0 20px;
  }

  .data-title {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }

  .data-tip {
    font-size: 12px;
    color: #999;
    margin-bottom: 20px;
  }

  .data-price {
    width: 100%;
    height: 54px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      font-size: 18px;
      font-weight: 800;
      color: #cd1b14;
    }

    .num {
      font-size: 12px;
      color: #999999;
    }
  }

  &.style_2 {
    .data-info:first-child {
      width: 588px;
      position: relative;

      .data-img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .data-box {
        box-sizing: border-box;
        width: 100%;
        height: 88px;
        background-color: rgba(0, 12, 40, 0.4);
        border-radius: 0px 0px 6px 6px;
        position: absolute;
        bottom: 0;
        z-index: 2;
      }

      .data-title {
        padding: 18px 0 10px;
        color: #fff;
        margin: 0;
      }

      .data-tip {
        display: none;
      }

      .data-price {
        height: auto;
        border: none;

        .price {
          color: #fff;
        }
      }
    }
  }

  .active-list {
    display: flex;

    // justify-content: space-between;
    .active-sub-title {
      height: 20px;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      -webkit-line-clamp: 1;
    }

    .active-name {
      height: 44px;
      font-size: 16px;
      color: #333;
    }

    &.style_7 {
      .active-info {
        width: 385px;
        height: 306px;
        background-color: #FFF;
        box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.2s ease-out;
        margin-right: 24px;

        &:hover {
          position: relative;
          transform: translateY(-6px);
        }
      }

      .active-info:nth-child(3n) {
        margin-right: 0;
      }

      .active-img {
        width: 100%;
        height: 217px;
        margin-bottom: 10px;
      }

      .active-cent {
        padding: 0 22px;
      }
    }

    &.style_8 {
      .active-info {
        box-sizing: border-box;
        width: 385px;
        height: 123px;
        padding: 0 18px;
        background-color: #FFF;
        box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
          position: relative;
          transform: translateY(-6px);
        }
      }

      .active-img {
        width: 160px;
        height: 90px;
        border-radius: 6px;
      }

      .active-cent {
        width: 180px;
      }
    }
    &.style_465{
      .active-info{
        width: 382px;
        height: 363px;
        padding: 12px;
        &:hover{
          .active-name{
            color: #2066BB;
          }
        }
      }
      .active-img{
        border-radius: 10px 10px 0px 0px;
        margin-bottom: 20px;
      }
      .active-name{
        height: auto;
        font-family: 'PingFangSC-bold';
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        text-align: center;
        margin-bottom: 16px;
      }
      .active-sub-title{
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #666666;
        line-height: 30px;
      }
    }
  }

  &.content2 {
    .boutique-info.big {
      width: 576px;
      position: relative;

      .boutique-img {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        z-index: 0;
      }

      .boutique-box {
        box-sizing: border-box;
        width: 100%;
        padding-top: 18px;
        background-color: rgba(0, 12, 40, 0.29);
        position: absolute;
        bottom: 0;
        left: 0;

        .boutique-title,
        .price,
        .num {
          color: #fff;
        }
      }

      .boutique-price {
        border: none;
      }
    }
  }
  &.live-content {
    width: 100%;
    padding-bottom: 232px;
    background: url(~@/assets/gx/live-content-bg.png) no-repeat center bottom/100%;
  }
  &.style_4{
    background: url(~@/assets/gx/lecturer-box-bg.png) no-repeat 0 0/100% 100% !important;
  }
}

.recommended-content {
  background-color: #fff;
  padding: 58px 0 46px;
}

.recommended-big-show {
  width: 588px;
  height: 432px;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  float: left;

  .recommended-box {
    padding: 0 20px;
  }

  .recommended-img {
    width: 100%;
    height: 318px;
    margin-bottom: 20px;
  }

  .recommended-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 24px;
  }

  .recommended-price {
    display: flex;
    justify-content: space-between;

    .price {
      font-size: 18px;
      font-weight: 800;
      color: #cd1b14;
    }

    .num {
      font-size: 12px;
      color: #999;
    }
  }
}

.recommended-list {
  width: 588px;
  overflow: hidden;
  float: right;

  .recommended-info {
    width: 282px;
    height: 204px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    float: left;
    margin-bottom: 22px;

    &:nth-child(2n-1) {
      margin-right: 24px;
    }
  }

  .recommended-img {
    width: 100%;
    height: 156px;
    margin-bottom: 14px;
  }

  .recommended-box {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
  }

  .recommended-title {
    width: 156px;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .recommended-price {
    font-size: 18px;
    font-weight: 800;
    color: #cd1b14;
  }
}

.series-list {
  display: flex;
  justify-content: space-between;

  .series-info {
    width: 384px;
    height: 250px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .series-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }

  .series-text {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 26px 20px 20px;
    background-color: rgba(0, 0, 0, 0.14);
    position: relative;
  }

  .series-title {
    font-size: 16px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 14px;
  }

  .marks {
    overflow: hidden;
    margin-bottom: 34px;

    .mark {
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 10px 2px 10px 2px;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      float: left;
      margin-right: 12px;
    }
  }

  .series-user-list {
    overflow: hidden;
  }

  .series-bottom {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .series-bottom-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
  }

  .series-price {
    font-size: 18px;
    color: #fff;
  }

  .series-user {
    float: left;
    margin-right: 10px;

    .series-user-img {
      width: 34px;
      height: 34px;
      border: 2px solid #fff;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.series-content6 {
  .series-list {
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      margin-right: 24px;
      margin-bottom: 24px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .series-info {
      width: 282px;
      height: 240px;
    }
  }
}

.lecturer-content {
  .lecturer-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.lecturer {
      justify-content: space-between;

      .lecturer-info {
        padding-bottom: 20px;
      }

      .lecturer-course {
        // height: 85px;
        -webkit-line-clamp: 6;
      }

      .lecturer-img {
        height: 282px;
        object-fit: cover;
      }
    }
  }

  .lecturer-info {
    width: 282px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
  }

  .openBox {
    width: 282px;
    height: 120px !important;

    .btn {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;

      p {
        width: 70px;
        height: 26px;
        background: #9f1106;
        color: #fff;
        font-size: 12px;
        line-height: 26px;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
      }
    }
  }

  .lecturer-img {
    width: 100%;
    height: auto;
  }

  .lecturer-name {
    display: flex;
    align-items: center;
    padding: 14px 20px 8px;
    font-size: 16px;
    font-weight: 900;
    color: #333;
  }

  .lecturer-leve {
    padding: 2px 4px;
    background-color: rgba(255, 195, 11, 0.14);
    font-size: 12px;
    color: #f5a623;
    margin-left: 8px;
  }

  .lecturer-course {
    padding: 0 20px 0px;
    font-size: 14px;
    color: #666;
  }
}

.blogroll {
  .content {
    padding: 59px 0 89px;
    font-size: 14px;
    color: #333;
  }

  .blogroll-link {
    font-size: 14px;
    color: #1d1a32;
    margin-left: 10px;
    margin-right: 52px;
  }
}

.service {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  margin-left: 625px;
  z-index: 10;
  cursor: pointer;

  .text-box {
    padding: 10px;
    padding-left: 0;
    position: relative;
    z-index: 1;
  }

  .text {
    width: 24px;
    padding: 40px 13px 10px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.2);
    background: url(~@/assets/headset.png) no-repeat center 11px #fff;
    background-size: 22px;
    border-radius: 5px;
    font-size: 12px;
  }

  .code {
    width: 110px;
    padding: 8px 0 91px;
    background: url(~@/assets/code.jpg) no-repeat center 44px #fff;
    background-size: 75px 75px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    left: -110px;
    bottom: 10px;
    z-index: 0;
    display: none;
  }

  &:hover {
    .text-box {
      overflow: hidden;
    }

    .text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .code {
      display: block;
      border-bottom-right-radius: 0;
    }
  }
}

// 文本课程
.textCourse {
  ul {
    justify-content: flex-start;

    li.lecturer-info {
      width: 384px;
      height: 330px;
      margin-right: 20px;

      img {
        height: 250px;
      }

      .lecturer-course span {
        color: #999;
      }
    }

    li:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.loadState {
  width: 1200px;
  margin: 0 auto;

  // height: 400px;
  // display: none;
  li {
    margin-top: 40px;
  }

  .title {
    width: 300px;
    height: 40px;
    background: rgba(0, 0, 0, 0.06);

  }

  div.list {
    margin-top: 34px;
    display: flex;
    justify-content: space-between;

    p {
      border-radius: 6px;
      width: 384px;
      height: 250px;
      background: rgba(0, 0, 0, 0.06);
      // background: linear-gradient(-45deg, rgba(0,0,0,.1),rgba(0,0,0,.05));
      // background-size: 400% 400%;
      // -webkit-animation: Gradient 15s ease infinite;
      // -moz-animation: Gradient 15s ease infinite;
      // animation: Gradient 15s ease infinite;

    }
  }
}

// 广告条
.vert_box {
  width: 1200px;
  margin: 20px auto;

  img {
    width: 1200px;
    max-height: 120px;
    object-fit: cover;
  }
}

// 公开考试样式
.examCont {
  ul {
    display: flex;

    li {
      width: 23%;
      background: #fff;
      box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
      border-radius: 6px;
      box-sizing: border-box;
      padding: 15px;
      margin-right: 2%;

      p.exam_name {
        font-size: 16px;
        margin-bottom: 10px;
      }

      div.time_ {
        p {
          font-size: 14px;
          color: #666;
          margin-bottom: 5px;
        }
      }

      .down_num {
        font-size: 14px;
        color: #666;
      }

      div.btn {
        display: inline-block;
        background: #254ed4;
        padding: 5px 10px;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
      }
    }
    &.style_111{
      li{
        width: 382px;
        padding: 12px;
        &:hover{
          .exam_name{
            color: #2066BB;
          }
        }
      }
      .exam-img{
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
      }
      p.exam_name{
        font-family: 'PingFangSC-bold';
        font-weight: bold;
        font-size: 24px;
        color: #333333;
      }
      .time_,
      .down_num{
        font-size: 16px;
      }
      .btn{
        box-sizing: border-box;
        width: 87px;
        height: 36px;
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// @-webkit-keyframes Gradient {
//     0% {
//         background-position: 0% 50%
//     }
//     50% {
//         background-position: 100% 50%
//     }
//     100% {
//         background-position: 0% 50%
//     }
// }

// @-moz-keyframes Gradient {
//     0% {
//         background-position: 0% 50%
//     }
//     50% {
//         background-position: 100% 50%
//     }
//     100% {
//         background-position: 0% 50%
//     }
// }

// @keyframes Gradient {
//     0% {
//         background-position: 0% 50%
//     }
//     50% {
//         background-position: 100% 50%
//     }
//     100% {
//         background-position: 0% 50%
//     }
// }
.content_cont_box {
  margin-top: 50px;

  .cont_box1 {
    width: 1200px;
    margin: 0 auto;
    position: relative;

    .more {
      text-align: right;
      position: absolute;
      right: 0;
    }

    .title_cont {
      width: 1200px;
      margin: 0 auto 30px;
      justify-content: space-between;

      .left_cont {
        align-items: flex-end;
        flex: 1;

        ul {
          flex: 1;
          margin-left: 44px;
          display: flex;
          align-items: center;

          li.active {
            color: #0065df;

            :after {
              content: "";
              position: absolute;
              left: 50%;
              bottom: -10px;
              transform: translateX(-50%);
              width: 20px;
              height: 2px;
              border-radius: 2px;
              background: #0065df;
            }
          }

          li {
            margin-right: 40px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666;
            position: relative;
            line-height: 32px;
          }
        }

        .cursor {
          cursor: pointer;
        }
      }

      .title {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333;
      }
    }

    .course_list {
      width: 100%;
      padding: 0px 0 44px;

      .data-list {
        width: 1224px;
        margin-left: -12px;
        display: flex;
        flex-wrap: wrap;
      }

      .data-info {
        width: 282px;
        background: #ffffff;
        box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
        border-radius: 6px;
        margin: 0 12px 24px 12px;
        transition: all 0.2s ease-out;

        &:hover {
          position: relative;
          transform: translateY(-6px);
        }
      }

      .data-img {
        width: 100%;
        height: 156px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        margin-bottom: 15px;
      }

      .data-box {
        padding: 0 20px;
      }

      .data-title {
        display: block;
        font-size: 16px;
        color: #333;
        margin-bottom: 8px;
      }

      .data-tip {
        font-size: 12px;
        color: #999;
        margin-bottom: 20px;
      }

      .data-price {
        width: 100%;
        height: 54px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
          font-size: 18px;
          font-weight: 800;
          color: #cd1b14;
        }

        .num {
          font-size: 12px;
          color: #999999;
        }
      }

      &.style_2 {
        .data-info:first-child {
          width: 588px;
          position: relative;

          .data-img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }

          .data-box {
            box-sizing: border-box;
            width: 100%;
            height: 88px;
            background-color: rgba(0, 12, 40, 0.4);
            border-radius: 0px 0px 6px 6px;
            position: absolute;
            bottom: 0;
            z-index: 2;
          }

          .data-title {
            padding: 18px 0 10px;
            color: #fff;
            margin: 0;
          }

          .data-tip {
            display: none;
          }

          .data-price {
            height: auto;
            border: none;

            .price {
              color: #fff;
            }
          }
        }
      }

      .active-list {
        display: flex;

        // justify-content: space-between;
        .active-sub-title {
          height: 20px;
          font-size: 14px;
          color: #999;
          line-height: 20px;
          -webkit-line-clamp: 1;
        }

        .active-name {
          height: 44px;
          font-size: 16px;
          color: #333;
        }

        &.style_7 {
          .active-info {
            width: 385px;
            height: 306px;
            background-color: #FFF;
            box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
            border-radius: 6px;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.2s ease-out;
            margin-right: 24px;

            &:hover {
              position: relative;
              transform: translateY(-6px);
            }
          }

          .active-info:nth-child(3n) {
            margin-right: 0;
          }

          .active-img {
            width: 100%;
            height: 217px;
            margin-bottom: 10px;
          }

          .active-cent {
            padding: 0 22px;
          }
        }

        &.style_8 {
          .active-info {
            box-sizing: border-box;
            width: 385px;
            height: 123px;
            padding: 0 18px;
            background-color: #FFF;
            box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
            border-radius: 6px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            transition: all 0.2s ease-out;

            &:hover {
              position: relative;
              transform: translateY(-6px);
            }
          }

          .active-img {
            width: 160px;
            height: 90px;
            border-radius: 6px;
          }

          .active-cent {
            width: 180px;
          }
        }
      }

      &.content2 {
        background-color: #fff;

        .boutique-info.big {
          width: 576px;
          position: relative;

          .boutique-img {
            position: absolute;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            z-index: 0;
          }

          .boutique-box {
            box-sizing: border-box;
            width: 100%;
            padding-top: 18px;
            background-color: rgba(0, 12, 40, 0.29);
            position: absolute;
            bottom: 0;
            left: 0;

            .boutique-title,
            .price,
            .num {
              color: #fff;
            }
          }

          .boutique-price {
            border: none;
          }
        }
      }
    }
  }

  .flex {
    display: flex;
  }

  padding: 58px 0 44px;

  .data-list {
    width: 1224px;
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
  }

  .data-info {
    width: 282px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    margin: 0 12px 24px 12px;
    transition: all 0.2s ease-out;

    &:hover {
      position: relative;
      transform: translateY(-6px);
    }
  }

  .data-img {
    width: 100%;
    height: 156px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 15px;
  }

  .data-box {
    padding: 0 20px;
  }

  .data-title {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }

  .data-tip {
    font-size: 12px;
    color: #999;
    margin-bottom: 20px;
  }

  .data-price {
    width: 100%;
    height: 54px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      font-size: 18px;
      font-weight: 800;
      color: #cd1b14;
    }

    .num {
      font-size: 12px;
      color: #999999;
    }
  }

  &.style_2 {
    .data-info:first-child {
      width: 588px;
      position: relative;

      .data-img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .data-box {
        box-sizing: border-box;
        width: 100%;
        height: 88px;
        background-color: rgba(0, 12, 40, 0.4);
        border-radius: 0px 0px 6px 6px;
        position: absolute;
        bottom: 0;
        z-index: 2;
      }

      .data-title {
        padding: 18px 0 10px;
        color: #fff;
        margin: 0;
      }

      .data-tip {
        display: none;
      }

      .data-price {
        height: auto;
        border: none;

        .price {
          color: #fff;
        }
      }
    }
  }

  .active-list {
    display: flex;

    // justify-content: space-between;
    .active-sub-title {
      height: 20px;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      -webkit-line-clamp: 1;
    }

    .active-name {
      height: 44px;
      font-size: 16px;
      color: #333;
    }

    &.style_7 {
      .active-info {
        width: 385px;
        height: 306px;
        background-color: #FFF;
        box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.2s ease-out;
        margin-right: 24px;

        &:hover {
          position: relative;
          transform: translateY(-6px);
        }
      }

      .active-info:nth-child(3n) {
        margin-right: 0;
      }

      .active-img {
        width: 100%;
        height: 217px;
        margin-bottom: 10px;
      }

      .active-cent {
        padding: 0 22px;
      }
    }

    &.style_8 {
      .active-info {
        box-sizing: border-box;
        width: 385px;
        height: 123px;
        padding: 0 18px;
        background-color: #FFF;
        box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
          position: relative;
          transform: translateY(-6px);
        }
      }

      .active-img {
        width: 160px;
        height: 90px;
        border-radius: 6px;
      }

      .active-cent {
        width: 180px;
      }
    }
  }

  &.content2 {
    background-color: #fff;

    .boutique-info.big {
      width: 576px;
      position: relative;

      .boutique-img {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        z-index: 0;
      }

      .boutique-box {
        box-sizing: border-box;
        width: 100%;
        padding-top: 18px;
        background-color: rgba(0, 12, 40, 0.29);
        position: absolute;
        bottom: 0;
        left: 0;

        .boutique-title,
        .price,
        .num {
          color: #fff;
        }
      }

      .boutique-price {
        border: none;
      }
    }
  }
}

// 弹框
.taskHint-dialog {
  position: absolute;
  width: 570px;
  height: 410px;
  border-radius: 20px 20px 20px 20px;
  background-image: url("../../assets/pc/lmxy_bgm.png");
  background-color: linear-gradient(180deg, #2973F0 0%, rgba(94, 149, 244, 0.75) 25%, rgba(255, 255, 255, 0) 100%);
  z-index: 9991;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .taskHint-content {
    position: relative;

    .close {
      position: absolute;
      color: #2973F0;
      font-size: 24px;
      font-weight: 700;
      right: 30px;
      top: 30px;
    }

    .taskHint-img {
      padding-top: 15px;
      text-align: center;
    }

    .taskHint-text {
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        font-weight: bold;
        font-size: 23px;
        color: #333333;
        line-height: 53px;
        text-align: center;
      }

      .text {
        width: 421px;
        height: 55px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
        text-align: center;
      }

      .but {
        display: block;
        width: 142px;
        height: 46px;
        background: #2973F0;
        border-radius: 8px 8px 8px 8px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 46px;
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}

.maskSight-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.swiper-box{
  position: relative;
  &.live-swiper{
    .swiper-pagination-bullets{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 30px;
      z-index: 2;
      .swiper-pagination-bullet{
        margin: 0 4px;
      }
    }
    .swiper-live-prev{
      left: -50px;
    }
    .swiper-live-next{
      right: -50px;
    }
    .live-info{
      width: 796px;
      position: relative;
      box-shadow: 0px 10px 18px 1px rgba(119,156,255,0.16);
      border-radius: 10px;
      overflow: hidden;
      overflow: hidden;
    }
    .live-img{
      width: 100%;
      height: 447px;
      object-fit: cover;
      background: green;
    }
    .live-conten{
      width: 100%;
      height: 117px;
      padding: 0 68px;
      background-color: rgba(16, 16, 16, 0.75);
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .live-mark{
      height:  34px;
      padding: 0 7px;
      background: #EFF3FE;
      border-radius: 5px;
      font-family: 'PingFangSC-bold';
      font-weight: bold;
      font-size: 14px;
      color: #1C2D73;
      line-height: 34px;
      margin-right: 17px;
    }
    .live-name{
      width: 470px;
      font-family: 'PingFangSC-bold';
      font-weight: bold;
      font-size: 20px;
      color: #fff;
      margin-bottom: 20px;
    }
    .live-date{
      font-size: 22px;
      color: #FFFFFF;
    }
  }
}
.lecturer-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .lecturer-info{
    box-sizing: border-box;
    width: 764px;
    height: 353px;
    padding: 40px 30px;
    background: rgba($color: #fff, $alpha: 0.5);
    box-shadow: none;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
  .lecturer-img{
    width: 217px;
    height: 271px;
    border-radius: 10px;
    object-fit: cover;
  }
  .lecturer-info-content{
    width: 465px;
  }
  .lecturer-info-name{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .lecturer-info-name-text{
    font-weight: bold;
    font-size: 24px;
    color: #333333;
  }
  .lecturer-info-level{
    height: 26px;
    padding: 0 11px;
    background: #FFF5E6;
    border: 1px solid #FF7600;
    border-radius: 0 13px 13px 0;
    border-left: none;
    font-weight: 500;
    font-size: 12px;
    color: #FF7600;
    line-height: 26px;
    margin-left: 30px;
    position: relative;
    &::before{
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      background: url('~@/assets/qh/ico-2.png') no-repeat 0 0/100% 100%;
      position: absolute;
      top: -2px;
      left: -20px;
    }
  }
  .lecturer-info-introduce{
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .lecturer-list{
    box-sizing: border-box;
    width: 421px;
    height: 353px;
    padding: 39px 0 0 58px;
    background: rgba($color: #fff, $alpha: 0.5);
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #FFFFFF;
  }
  .lecturer-avartar-box{
    width: 123px;
    height: 123px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0);
    float: left;
    margin: 0 60px 30px 0;
    cursor: pointer;
    &:nth-child(2n){
      margin-right: 0;
    }
    &.active{
      border-color: #3164F6;
      position: relative;
      &::after{
        content: '';
        display: block;
        width: 10px;
        height: 14px;
        background: url('~@/assets/qh/arrows-2.png') no-repeat 0 0/100% 100%;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .lecturer-avartar{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
.news-content{
  width: 1200px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .news-left{
    width: 750px;
    cursor: pointer;
  }
  .news-img{
    width: 100%;
    height: 429px;
    border-radius: 5px;
    object-fit: cover;
  }
  .news-left-content{
    box-sizing: border-box;
    width: 100%;
    height: 204px;
    padding: 30px;
    background: linear-gradient( 180deg, #F7FAFF 0%, #FFFFFF 100%);
    box-shadow: 0px 10px 18px 1px rgba(175,196,255,0.16);
    border-radius: 0px 0px 5px 5px;
    border: 2px solid #FFF;
    display: flex;
    flex-direction: column;
  }
  .news-title{
    font-family: 'PingFangSC-bold';
    font-weight: bold;
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
  }
  .news-subtitle{
    font-weight: 500;
    font-size: 18px;
    color: #666;
  }
  .news-date{
    font-weight: 500;
    font-size: 18px;
    color: #666;
    margin-top: auto;
  }
  .new-list{
    width: 428px;
  }
  .news-info{
    box-sizing: border-box;
    width: 100%;
    height: 133px;
    padding: 28px 13px 0 24px;
    background: linear-gradient( 180deg, #F7FAFF 0%, #FFFFFF 100%);
    box-shadow: 0px 5px 10px 1px rgba(175,196,255,0.16);
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    margin-bottom: 15px;
    cursor: pointer;
    &:last-child{
      margin-bottom: 0;
    }
    &.active{
      position: relative;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: linear-gradient( 300deg, #2066BB 0%, #FFFFFF 100%);
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .news-info-title{
        color: #2066BB;
      }
    }
  }
  .news-info-title{
    font-weight: 500;
    font-size: 22px;
    color: #666666;
    margin-bottom: 22px;
  }
  .news-info-date{
    font-weight: 500;
    font-size: 18px;
    color: #666666;
  }
}
.course-module{
  padding-bottom: 173px;
  background: url(~@/assets/gx/course-module-bg.png) no-repeat 0 bottom/100% #F5F7FD;
  .course-types{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;
  }
  .course-type{
    height: 58px;
    padding: 0 24px;
    border-radius: 42px;
    border: 1px solid #2066BB;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #2066BB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    cursor: pointer;
    &.active{
      background: #2066BB;
      color: #fff;
    }
  }
}
</style>